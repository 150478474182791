<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-17 14:59:19
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-16 22:47:58
 * @Description  : 修改密码弹窗
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("modify_password") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="resetForm"
        status-icon
        ref="resetRef"
        label-width="180px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('old_password')">
          <el-input
            prefix-icon="el-icon-unlock"
            :placeholder="$t('please_enter_your_old_password')"
            show-password
            clearable
            :type="passwordType"
            v-model="oldPwd"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('new_password')" class="psw-input">
          <el-input
            prefix-icon="el-icon-unlock"
            :placeholder="$t('please_enter_your_new_password')"
            show-password
            clearable
            :type="passwordType"
            v-model="newPsw"
            autocomplete="off"
          >
          </el-input>
          <small>{{ $t("key_password_tip") }}</small>
        </el-form-item>
        <el-form-item prop="checkPass" :label="$t('confirm_new_password')">
          <el-input
            prefix-icon="el-icon-unlock"
            :placeholder="$t('please_enter_the_new_password_again')"
            :type="passwordType"
            show-password
            clearable
            v-model="newPsw2"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { userModifyPwdApi } from "@/api/api.js";
import { encrypt } from "@/utils/RSAUtil";
import { RegexUtils } from "@/utils/validate/regex";

export default {
  name: "ChangePswDialog",
  data() {
    return {
      resetForm: {
        password: "",
        salt: "",
        oldPwd: "",
      },
      passwordType: "password",
      oldPwd: "",
      newPsw: "",
      newPsw2: "",
      dialogFormVisible: false,
    };
  },
  methods: {
    show() {
      this.showDialog();
    },
    showDialog() {
      this.dialogFormVisible = true;
    },
    handelOk() {
      if (!this.oldPwd) {
        this.$message.error(this.$t("old_password_cannot_be_empty"));

        return;
      }

      if (!this.newPsw || !this.newPsw2) {
        this.$message.error(this.$t("new_password_cannot_be_empty"));

        return;
      }

      if (!RegexUtils.checkPsw(this.newPsw)) {
        return;
      }

      if (this.newPsw != this.newPsw2) {
        this.$message.error(
          this.$t("the_two_password_inputs_are_inconsistent")
        );

        return;
      }

      let timestamp = new Date().getTime();
      let params = {
        oldPwd: encrypt(this.oldPwd + timestamp),
        salt: timestamp,
        password: encrypt(this.newPsw + timestamp),
      };

      console.log("params: ", params);

      userModifyPwdApi(params).then((r) => {
        console.log("=====", r);

        this.$message.success(this.$t("modify_success_please_log_in_again"));

        this.goPages("/login");
      });
    },
  },
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
};
</script>

<style scoped lang="less">
// @import "../Login.less";
.container {
  .title {
    display: flex;
    height: 50px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .tab-box {
    //   height: 28px;
    //   border-radius: 18px;
    display: flex;
    //   margin-top: 20px;
    //   margin-left: 10px;
    //   margin-right: 10px;
    width: 400px;
    background: white;
    flex-direction: row;
    //   justify-content: space-evenly;
    align-items: center;
    line-height: 48px;
    height: 48px;
  }
  .tab-item {
    //   border-radius: 18px;
    //   margin: 8px;
    font-size: 14px;
    width: 200px;
    text-align: center;

    //   padding: 0px 4px;
    justify-content: center;
    background: #edeeef;
  }

  .is-active {
    background-color: #86c6ff;
    font-size: 16px;
    font-weight: Normal;
    text-align: center;
    line-height: 48px;
    height: 48px;

    color: #ffffff;
  }
  .send {
    font-size: 14px;
    font-weight: Normal;
    text-align: center;
    color: #6da7e0;
    line-height: 18px;
  }
  .demo-ruleForm {
    margin-top: 20px;
  }
}
.width-100 {
  width: 100%;
}
.forms {
  width: 70%;
  margin: 0 auto;
}
.psw-input {
  /deep/.el-form-item__content {
    line-height: 20px;
  }
  small {
    font-size: 12px;
    color: red;
  }
}
</style>
