<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-16 17:20:01
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-03 11:33:46
 * @Description  : 用户中心
-->
<template>
  <div class="container">
    <div class="title font-heavy">
      {{ $t("user_center") }}
    </div>
    <div class="box">
      <div class="label font-heavy">{{ $t("security_settings") }}</div>
      <el-divider></el-divider>
      <div class="info">
        <div class="item">{{ $t("account") }}：{{ form.userId }}</div>
        <div class="item">{{ $t("country_region") }}：{{ form.region }}</div>
        <div class="item">
          {{ $t("username") }}：{{ form.username }}
          <img
            src="../../assets/ic_edit.png"
            alt=""
            width="15"
            @click="changeUsername"
          />
        </div>
        <div class="item">
          {{ $t("email") }}：{{ form.email }}
          <img
            src="../../assets/ic_edit.png"
            alt=""
            width="15"
            @click="changeEmail"
          />
        </div>
        <div class="item" v-show="isChina">
          {{ $t("mobile") }}：{{ form.mobileNum }}
          <img
            src="../../assets/ic_edit.png"
            alt=""
            width="15"
            @click="changeMobile"
          />
        </div>
        <el-button
          type="primary"
          @click="changePsw"
          style="height:41px;margin:7px 0 13px 0"
        >
          {{ $t("modify_password") }}</el-button
        >
      </div>
      <!-- <el-form ref="form" :model="form" label-width="100px">
        <el-form-item :label="$t('account')">
          <el-input v-model="form.userId" disabled></el-input>
        </el-form-item>

        <el-form-item :label="$t('username')">
          <el-row>
            <el-col :span="21">
              <el-input v-model="form.username" disabled> </el-input>
            </el-col>
            <el-col :span="1" :offset="1">
              <el-button type="primary" @click="changeUsername">
                {{ $t("modify") }}</el-button
              ></el-col
            >
          </el-row>
        </el-form-item>

        <el-form-item :label="$t('email')">
          <el-row>
            <el-col :span="21">
              <el-input v-model="form.email" disabled> </el-input>
            </el-col>
            <el-col :span="1" :offset="1">
              <el-button type="primary" @click="changeEmail">
                {{ $t("modify") }}</el-button
              ></el-col
            >
          </el-row>
        </el-form-item>

        <el-form-item :label="$t('mobile')" v-if="isChina">
          <el-row>
            <el-col :span="21">
              <el-input v-model="form.mobileNum" disabled> </el-input>
            </el-col>
            <el-col :span="1" :offset="1">
              <el-button type="primary" @click="changeMobile">
                {{ $t("modify") }}</el-button
              ></el-col
            >
          </el-row>
        </el-form-item>

        <el-form-item :label="$t('password')">
          <el-row>
            <el-col :span="21">
              <el-input v-model="form.psw" disabled> </el-input>
            </el-col>
            <el-col :span="1" :offset="1">
              <el-button type="primary" @click="changePsw">
                {{ $t("modify") }}</el-button
              ></el-col
            >
          </el-row>
        </el-form-item>
      </el-form> -->
    </div>
    <div class="box2">
      <div class="title-bar">
        <div class="label font-heavy">
          {{ $t("my_organizations") }}
        </div>

        <el-button type="primary" @click="changeNewOrgan" class="btn-add">
          +{{ $t("new_organization") }}</el-button
        >
      </div>

      <el-table
        :data="data"
        style="width: auto;margin:18px 71px 86px 42px"
        border
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :empty-text="$t('no_data')"
      >
        <!-- <el-table-column prop="owner.name" :label="$t('organ_by_create')">
          <template v-slot:default="scope">
            <span @click="goOrganDetail(scope.row.owner)" class="organ-detail"
              >{{ scope.row.owner.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="manager.name" :label="$t('organ_by_manage')">
          <template v-slot:default="scope">
            <span @click="goOrganDetail(scope.row.manager)" class="organ-detail"
              >{{ scope.row.manager.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="view.name" :label="$t('organ_by_add')">
          <template v-slot:default="scope">
            <span @click="goOrganDetail(scope.row.view)" class="organ-detail"
              >{{ scope.row.view.name }}
            </span>
          </template>
        </el-table-column> -->

        <el-table-column prop="name" :label="$t('organ_name')">
          <template v-slot:default="scope">
            <span @click="goOrganDetail(scope.row)" class="organ-detail"
              >{{ scope.row.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="ownerInd" :label="$t('role')">
          <template v-slot:default="scope">
            <span v-if="scope.row.ownerInd == '1'">{{ $t("owner") }} </span>
            <span
              v-else-if="scope.row.ownerInd != '1' && scope.row.limits == '1'"
              >{{ $t("administrator") }}
            </span>
            <span
              v-else-if="scope.row.ownerInd != '1' && scope.row.limits == '0'"
              >{{ $t("member") }}
            </span>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="orgId" :label="$t('organ_id')">
        </el-table-column> -->

        <el-table-column prop="invitationCode" :label="$t('organ_code')">
          <template v-slot:default="scope">
            <span v-if="scope.row.ownerInd == '1'"
              >{{ scope.row.invitationCode }}
            </span>
            <span
              v-else-if="scope.row.ownerInd != '1' && scope.row.limits == '1'"
              >{{ scope.row.invitationCode }}</span
            >
            <span
              v-else-if="scope.row.ownerInd != '1' && scope.row.limits == '0'"
              >N/A</span
            >
          </template>
        </el-table-column>

        <el-table-column prop="note" :label="$t('note')"> </el-table-column>
      </el-table>
    </div>

    <ChangePswDialog ref="pswDialog" @ok="getUserInfo"></ChangePswDialog>
    <ChangePhoneDialog ref="mobileDialog" @ok="getUserInfo"></ChangePhoneDialog>
    <ChangeEmailDialog ref="emailDialog" @ok="getUserInfo"></ChangeEmailDialog>
    <ChangeUsernameDialog
      ref="usernameDialog"
      @ok="getUserInfo"
    ></ChangeUsernameDialog>
    <AddOrganDialog
      ref="addNewOrganDialog"
      @ok="getUserCenterList"
    ></AddOrganDialog>
  </div>
</template>

<script>
import ChangePswDialog from "./modal/ChangePswDialog.vue";
import ChangePhoneDialog from "./modal/ChangePhoneDialog.vue";
import ChangeEmailDialog from "./modal/ChangeEmailDialog.vue";
import ChangeUsernameDialog from "./modal/ChangeUsernameDialog.vue";
import AddOrganDialog from "./modal/AddOrganDialog.vue";

import { myselfApi, userCenterListApi } from "@/api/api.js";

export default {
  name: "User",
  data() {
    return {
      form: {
        userId: "",
        mobileNum: "",
        email: "",
        username: "",
        region: "",
      },
      isChina: true,
      // ownerList: [],
      // managerList: [],
      // viewList: [],
      region: "",
      data: [],
    };
  },
  methods: {
    changePsw() {
      this.$refs.pswDialog.showDialog();
    },
    changeMobile() {
      this.$refs.mobileDialog.showDialog();
    },
    changeEmail() {
      this.$refs.emailDialog.showDialog();
    },
    changeUsername() {
      this.$refs.usernameDialog.showDialog();
    },
    changeNewOrgan() {
      this.$refs.addNewOrganDialog.showDialog(this.form.userId);
    },

    getUserInfo() {
      myselfApi().then((r) => {
        console.log("=====", r);
        this.form.userId = r.data.userId;
        this.form.mobileNum = r.data.mobileNum;
        this.form.email = r.data.email;
        this.form.username = r.data.name;
        switch (r.data.region) {
          case "D_China":
            this.form.region = this.$t("D_China");
            break;
          case "D_EUA":
            this.form.region = this.$t("D_EUA");
            break;
          case "D_USA":
            this.form.region = this.$t("D_USA");
            break;

          default:
            break;
        }

        localStorage.setItem("mobileNum", r.data.mobileNum);
        localStorage.setItem("email", r.data.email);
        localStorage.setItem("username", r.data.name);

        // localStorage.setItem("region", r.data.region);

        // this.$notify({
        //   title: "成功",
        //   message: r.message,
        //   type: "success",
        // });
      });
    },
    getUserCenterList() {
      userCenterListApi().then((r) => {
        console.log("=====", r);
        // this.ownerList = r.data.ownerList;
        // this.managerList = r.data.managerList;
        // this.viewList = r.data.viewList;
        this.data = r.data;
      });
    },
    goOrganDetail(row) {
      let obj = {
        orgId: row.orgId,
        orgName: row.name,
        note: row.note,
        code: row.invitationCode,
      };
      this.goPages("/organDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
  },
  props: {},
  components: {
    ChangePswDialog,
    ChangePhoneDialog,
    ChangeEmailDialog,
    ChangeUsernameDialog,
    AddOrganDialog,
  },
  computed: {
    // rows() {
    //   const maxLength = Math.max(
    //     this.ownerList.length,
    //     this.managerList.length,
    //     this.viewList.length
    //   );
    //   const rows = [];

    //   for (let i = 0; i < maxLength; i++) {
    //     rows.push({
    //       owner: this.ownerList[i] || {},
    //       manager: this.managerList[i] || {},
    //       view: this.viewList[i] || {},
    //     });
    //   }

    //   return rows;
    // },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;

    // this.form.username = localStorage.getItem("username");
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    console.log("this.isChina: ", this.isChina);

    this.getUserInfo();
    this.getUserCenterList();
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  // padding: 34px 0 89px 29px;

  padding: 34px 68px 0 31px;
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .box {
    background: white;
    // padding: 0 0 10px 0;
    // margin-right: 69px;
    border-radius: 8px;
    border: 1px solid rgba(187, 187, 187, 1);
    .el-divider {
      background-color: rgba(187, 187, 187, 1);
    }
    .label {
      font-size: 14px;
      color: gray;
      height: 48px;
      line-height: 48px;
      width: 100%;
      margin-left: 40px;
      // padding-top: 10px;
      margin-bottom: -25px;
    }
    .info {
      margin-left: 42px;
      .item {
        color: #383838;
        margin: 10px 0;
        img {
          cursor: pointer;
        }
      }
    }
  }
}

.box2 {
  background: white;
  border-radius: 8px;
  // padding: 22px 71px 10px 42px;
  margin-top: 22px;
  // margin-right: 69px;
  height: auto;
  border: 1px solid rgba(187, 187, 187, 1);
  // .el-divider{
  //   background-color:rgba(187,187,187,1);
  // }

  .title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .label {
      font-size: 14px;
      color: gray;
      height: 48px;
      line-height: 48px;
      margin-top: 22px;
      margin-left: 42px;
      // width: 100%;
      // margin-left: 40px;
      // padding-top: 10px;
      // margin-bottom: -25px;
    }
    .btn-add {
      height: 41px;
      margin-top: 18px;
      margin-right: 71px;
    }
  }
}
.title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}
.organ-detail {
  color: #1a72f4;
  text-decoration: underline;
  cursor: pointer;
}
</style>
