<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-18 09:55:16
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-16 22:47:53
 * @Description  : 修改手机号弹窗
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("modify_mobile") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="resetForm"
        status-icon
        ref="resetRef"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('new_mobile')">
          <el-input
            :placeholder="$t('please_enter_your_mobile')"
            clearable
            v-model="resetForm.subject"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('verification_code')">
          <el-input
            v-model="resetForm.verifyCode"
            maxlength="6"
            autocomplete="off"
            :placeholder="$t('verification_code')"
          >
            <template #append>
              <div @click="getSMSCode" class="send">
                {{ counting ? `${timerCount}s` : $t("get_verification_code") }}
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { verifyCodeModifyConcatApi, resetMobileNumApi } from "@/api/api.js";
// import { encrypt } from "@/utils/RSAUtil";
import { RegexUtils } from "@/utils/validate/regex";

export default {
  name: "ChangePhoneDialog",
  data() {
    return {
      resetForm: {
        subject: "",
        verifyCode: "",
        verifyId: "",
      },
      dialogFormVisible: false,
      timerCount: 0,
      counting: false, // 是否计时中
      interval1: null,
      yzBtnEnable: true,
      subject: "",
      verifyId: "",
    };
  },
  methods: {
    show() {
      this.showDialog();
    },
    showDialog() {
      this.dialogFormVisible = true;
    },
    getSMSCode() {
      if (this.resetForm.subject === "") {
        this.$message.error(this.$t("please_enter_your_mobile"));

        return;
      }

      if (!RegexUtils.checkMobile(this.resetForm.subject)) {
        this.$message.error(this.$t("the_phone_number_format_is_incorrect"));

        return;
      }
      let timer = 180;
      const loading = this.$loading({
        lock: true,
        text: this.$t("obtaining_verification_code"),

        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        subject: this.resetForm.subject,
      };
      console.log("params: ", params);

      verifyCodeModifyConcatApi(params).then((res) => {
        loading.close();
        console.log("verifyCodeRegisterApi----" + JSON.stringify(res));
        if (res) {
          this.$message.success(this.$t("verification_code_sent_successfully"));

          this.interval1 = setInterval(() => {
            timer -= 1;
            if (timer <= 0) {
              this.clearIntervalHandel();
            } else {
              this.timerCount = timer;
              this.counting = true;
              this.yzBtnEnable = false;
            }
          }, 1000);
          this.resetForm.verifyId = res.data;
        }
      });
    },

    clearIntervalHandel() {
      clearInterval(this.interval1);
      this.yzBtnEnable = true;
      this.counting = false;
    },

    handelOk() {
      if (this.resetForm.subject === "") {
        this.$message.error(this.$t("please_enter_your_mobile"));
        return;
      }
      // let timestamp = new Date().getTime();
      // let params = {
      //   oldPwd: encrypt(this.oldPwd + timestamp),
      //   salt: timestamp,
      //   password: encrypt(this.newPsw + timestamp),
      // };

      // console.log("params: ", params);

      resetMobileNumApi(this.resetForm).then((r) => {
        console.log("=====", r);

        this.$message.success(this.$t("modify_success"));
        this.dialogFormVisible = false;
        this.$emit("ok");
      });
    },
  },
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
};
</script>

<style scoped lang="less">
// @import "../Login.less";
.container {
  .title {
    display: flex;
    height: 50px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .tab-box {
    //   height: 28px;
    //   border-radius: 18px;
    display: flex;
    //   margin-top: 20px;
    //   margin-left: 10px;
    //   margin-right: 10px;
    width: 400px;
    background: white;
    flex-direction: row;
    //   justify-content: space-evenly;
    align-items: center;
    line-height: 48px;
    height: 48px;
  }
  .tab-item {
    //   border-radius: 18px;
    //   margin: 8px;
    font-size: 14px;
    width: 200px;
    text-align: center;

    //   padding: 0px 4px;
    justify-content: center;
    background: #edeeef;
  }

  .is-active {
    background-color: #86c6ff;
    font-size: 16px;
    font-weight: Normal;
    text-align: center;
    line-height: 48px;
    height: 48px;

    color: #ffffff;
  }
  .send {
    font-size: 14px;
    font-weight: Normal;
    text-align: center;
    color: #6da7e0;
    line-height: 18px;
  }
  .demo-ruleForm {
    margin-top: 20px;
  }
}
.width-100 {
  width: 100%;
}
.forms {
  width: 70%;
  margin: 0 auto;
}
</style>
