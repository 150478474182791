<!--
 * @Author       : JiangChao
 * @Date         : 2024-07-18 13:18:29
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-16 22:47:46
 * @Description  : 新增组织弹窗
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("add_new_organ") }}
      </div>
    </template>
    <el-form
      :model="form"
      status-icon
      ref="resetRef"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item :label="$t('organ_code')">
        <el-input
          clearable
          v-model="form.invitationCode"
          autocomplete="off"
          :placeholder="$t('please_enter')"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { userJoinOrgApi } from "@/api/api.js";

export default {
  name: "AddOrganDialog",
  data() {
    return {
      form: {},
      dialogFormVisible: false,
      userId: "",
    };
  },
  methods: {
    showDialog(id) {
      this.userId = id;
      this.dialogFormVisible = true;
    },
    handelOk() {
      if (!this.form.invitationCode) {
        this.$message.error(this.$t("organ_code_cannot_be_empty"));
        return;
      }

      let params = {
        userId: this.userId,
        ...this.form,
      };

      userJoinOrgApi(params).then(() => {
        this.$message.success(this.$t("joined_successfully"));
        this.dialogFormVisible = false;
        this.$emit("ok");
      });
    },
  },
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
};
</script>

<style scoped lang="less">
.container {
  .title {
    display: flex;
    height: 50px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .send {
    font-size: 14px;
    font-weight: Normal;
    text-align: center;
    color: #6da7e0;
    line-height: 18px;
  }
  .demo-ruleForm {
    margin-top: 20px;
  }
}
.width-100 {
  width: 100%;
}
.forms {
  width: 70%;
  margin: 0 auto;
}
</style>
